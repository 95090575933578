import { LANDING_ROUTE_KEY } from "@/enums";
import { ConfigProvider, Divider, Flex } from "antd";
import { Inter } from "next/font/google";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

import { LANDING_MENU_ITEM_ENUM } from "@/constants";

import { LANDING_PATHNAME } from "../constants/pathname";
import styles from "./index.module.less";

const inter = Inter({ subsets: ["latin"] });

const PRODUCT_FEATURES = [
  LANDING_ROUTE_KEY.RESUME_AI,
  LANDING_ROUTE_KEY.JOB_MATCHING,
  LANDING_ROUTE_KEY.INSIDER_CONNECTIONS,
  LANDING_ROUTE_KEY.AI_COPILOT_ORION,
  LANDING_ROUTE_KEY.H1B_JOBS,
];
const GET_HELP = [
  LANDING_ROUTE_KEY.MASTERING_INTERVIEWS,
  LANDING_ROUTE_KEY.ENERGIZE_YOUR_JOB_SEARCH,
  LANDING_ROUTE_KEY.HOW_TO_WRITE_A_RESUME,
  LANDING_ROUTE_KEY.HOW_TO_GET_A_INTERNSHIP,
];

const REDIRECT_LINKS = [
  {
    title: "Product Features",
    links: PRODUCT_FEATURES,
  },

  {
    title: "Get Help",
    links: GET_HELP,
  },
];

const LinksRender: React.FC<{ gap: number }> = ({ gap }) =>
  REDIRECT_LINKS.map(({ title, links }) => (
    <Flex key={title} gap={gap} vertical>
      <div className={styles["footer-title"]}>{title}</div>
      {links.map(key => (
        <Link key={key} className={styles["footer-item"]} href={LANDING_MENU_ITEM_ENUM[key].route}>
          {LANDING_MENU_ITEM_ENUM[key].text}
        </Link>
      ))}
    </Flex>
  ));

const MobileContent = () => {
  return (
    <div className={styles["mobile-bg-class"]}>
      <Flex justify="end">
        <div>
          <Image
            className={styles["icon-right-class"]}
            src={"/newimages/404/503.svg"}
            width={300}
            height={225}
            alt="close detail"
          />
        </div>
      </Flex>
      <div className={styles["mobile-content-wrapper"]}>
        <Flex vertical gap={4}>
          <div className={styles["subtitle-class"]}>Page Not Found</div>
          <div className={styles["subtitle-desc-calss"]}>
            The page you are looking for may no longer exist. Try another page again.
          </div>
        </Flex>
        <Link className={styles["content-button"]} href={LANDING_PATHNAME}>
          Back to Home Page
        </Link>
        <div className={styles["content-divider"]}></div>
        <div className={styles["subtitle-desc-calss"]}>
          Need help searching for the right job? Try one of the links below:
        </div>
        <Flex gap={24} vertical>
          <LinksRender gap={8} />
        </Flex>
        <Image
          className={styles["icon-class"]}
          src={"/newimages/logo_black.svg"}
          width={96}
          height={24}
          alt="close detail"
        />
      </div>
    </div>
  );
};

const PcContent = () => {
  return (
    <div className={styles["bg-class"]}>
      <div className={styles["error-content-class"]}>
        <div className={styles["error-content-wrapper"]}>
          <Flex vertical gap={4}>
            <div className={styles["subtitle-class"]}>Page Not Found</div>
            <div className={styles["subtitle-desc-calss"]}>
              The page you are looking for may no longer exist. Try another page again.
            </div>
          </Flex>
          <Link className={styles["content-button"]} href={LANDING_PATHNAME}>
            Back to Home Page
          </Link>
          <div className={styles["content-divider"]}></div>
          <div className={styles["subtitle-desc-calss"]}>
            Need help searching for the right job? Try one of the links below:
          </div>
          <Flex gap={72}>
            <LinksRender gap={12} />
          </Flex>
          <Image
            className={styles["icon-class"]}
            src={"/newimages/logo_black.svg"}
            width={96}
            height={24}
            alt="close detail"
          />
        </div>
      </div>
      <Image
        className={styles["icon-right-class"]}
        src={"/newimages/404/503.svg"}
        width={640}
        height={480}
        alt="close detail"
      />
    </div>
  );
};

export default function Custom404() {
  // TODO: give a better 404 page
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: inter.style.fontFamily,
          colorBgLayout: "transparent",
        },
      }}
    >
      {isMobile ? <MobileContent /> : <PcContent />}
    </ConfigProvider>
  );
}
